.loader {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 20px auto;
}

.loader span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(calc(18deg * var(--i)));
}

.loader span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  background: #00ccff;
  box-shadow: 0 0 10px #00ccff,
              0 0 20px #00ccff,
              0 0 40px #00ccff,
              0 0 60px #00ccff,
              0 0 80px #00ccff,
              0 0 100px #00ccff;
  animation: animate 2s linear infinite;
  animation-delay: calc(0.1s * var(--i));
}

@keyframes animate {
  0% {
    transform: scale(1);
  }
  80%,
  100% {
    transform: scale(0);
  }
}
