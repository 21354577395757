body {
  overflow-x: hidden;
}

main {
  min-height: 80vh;
}

input:focus {
  outline: none;
}

input[type='file']{
  color: black;
}